import { Component, OnInit } from '@angular/core';
import { VideoData } from '../Data/VideoData';

@Component({
  selector: 'app-main-page',
  templateUrl: './main-page.component.html',
  styleUrls: ['./main-page.component.scss']
})
export class MainPageComponent implements OnInit {

  elements:VideoData[] = [
    {url:this.GetVimeoURL("766835705"), title:"Toon Shader. ABC Project."},
    {url:this.GetVimeoURL("766216012"), title:"Uzumaki. Unity + Houdini."},
    {url:this.GetVimeoURL("770560096"), title:"Tofu. Houdini soft body simulation."},
    {url:this.GetVimeoURL("736562275"), title:"Dynamic foam. Based on Sea of Thieves Siggraph presentation."},
    {url:this.GetVimeoURL("766939739"), title:"Compute Shader. Boids Simulation."},
    {url:this.GetVimeoURL("583816969"), title:"Compute Shader. Cyclic Cellular Automata."},
    {url:this.GetVimeoURL("766308960"), title:"Raymarched Synthwave shader."},
    {url:this.GetVimeoURL("539645594"), title:"Procedural Card Shader."},
    {url:this.GetVimeoURL("531460356"), title:"Environment Shaders."},
    {url:this.GetVimeoURL("382829847"), title:"Blender. Vertex Color Manipulation."},
    {url:this.GetVimeoURL("486825655"), title:"Math Visualization."},
    {url:this.GetVimeoURL("531455605"), title:"Sylized Waterfall."},
    {url:this.GetVimeoURL("531449814"), title:"Card VFX."},
    {url:this.GetVimeoURL("539647241"), title:"Card VFX Breakdown."},
    {url:this.GetVimeoURL("382830671"), title:"Sci Fi VFX."},
    {url:this.GetVimeoURL("382831019"), title:"Anime Waterfall."},

  ]

  softwares:string[] =["Unity", "Houdini", "Blender", "Substance Designer","Substance Painter", "UE5"]

  kirbySounds:AudioSample[] = [
    {url:"https://files.gamebanana.com/preview/sounds/poyo_.mp3",volume:.2},
    {url:"https://files.gamebanana.com/preview/sounds/kirby14.ogg",volume:.15},
    {url:"https://files.gamebanana.com/preview/sounds/untitled_f8fed.mp3",volume:.35},
    {url:"https://files.gamebanana.com/preview/sounds/kritter_hitsound.mp3",volume:.5}
  ]

  inited:boolean = false;

  audio:HTMLAudioElement = new Audio();

  constructor() {
    this.audio.volume=.2;
   }

  ngOnInit() {
  }

  GetVimeoURL(code:string):string{
    var url = `https://player.vimeo.com/video/${code}?h=1dbc60f7c4&loop=1&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`

    return url;
  }

  PlayAudio():void{
    let soundIndex=0;
    if (!this.inited) {
      this.inited=true;
    }
    else{
      soundIndex =Math.floor(Math.random()*this.kirbySounds.length);
    }
    this.audio.src = this.kirbySounds[soundIndex].url;
    this.audio.volume=this.kirbySounds[soundIndex].volume;
    this.audio.play();
  }

}

interface AudioSample{
  url:string;
  volume:number;
}



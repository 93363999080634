import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { VideoData } from '../Data/VideoData';

@Component({
  selector: 'app-element',
  templateUrl: './element.component.html',
  styleUrls: ['./element.component.scss']
})
export class ElementComponent {

  @Input()data:VideoData;

  constructor(private _sanitizer:DomSanitizer) {
  }


  cleanURL():SafeUrl{
    return this._sanitizer.bypassSecurityTrustResourceUrl(this.data.url);
  }


}
